import config from '#config';

import cookies from '#utils/cookies';

interface ZendeskSupportedWindow extends Window {
  [key: string]: any;
}

const getLinkers = async () => {
  const globalWindowObject = window as ZendeskSupportedWindow;
  const { ga } = globalWindowObject;
  try {
    const cidGenerator = () => {
      const half = Math.floor(Math.random() * (2147483647 - 1000000000 + 1)) + 1000000000;
      const time = new Date().getTime().toString().substring(0, 10);
      return `${half}.${time}`;
    };

    const detectBlocker = () => {
      let height;
      const blockerElement = document.querySelector('#blokerdetect');
      if (blockerElement instanceof HTMLElement) {
        height = blockerElement?.offsetHeight;
      }
      return height === 0;
    };

    const customViewSend = (gaCookie: string) => {
      // prepare params for manual ga pageview event
      const cid = gaCookie?.indexOf('GA') !== 0 ? cidGenerator() : gaCookie.substring(6);
      const dt = document.getElementsByTagName('title')[0].innerHTML.replaceAll(' ', '%20');
      const dr = document.referrer;
      const dh = window.location.host;
      const dp = window.location.pathname;
      const cd11 = navigator.userAgent.replaceAll(' ', '%20');
      const sr = `${window.screen.width}x${window.screen.height}`;
      const cd8 = cookies.get('sequence');
      const vp = `${window.innerWidth}x${window.innerHeight}`;
      const sd = window.screen.colorDepth;
      const body = {
        v: '1',
        tid: config.GTM_ID,
        ul: 'en',
        de: 'UTF-8',
        je: '0',
        dl: encodeURIComponent(window.location.href),
        cid,
        dt,
        dr,
        dh,
        dp,
        cd11,
        sr,
        cd8,
        cd3: cid,
        vp,
        sd,
      };
      // @ts-ignore
      const params = new URLSearchParams(body).toString();
      const url = `/collect?${params}`;

      return fetch(url).then(() => {
        document.cookie = `_ga=GA1.2.${cid};path=/;`;
      });
    };

    const getLinkers = setInterval(() => {
      const isBlocker = detectBlocker();
      const gaCookie = cookies.get('_ga') || '';
      if (isBlocker) {
        customViewSend(gaCookie).then(() => {
          clearInterval(getLinkers);
        });
      }
      if (ga) {
        try {
          const currentGid = ga?.getAll?.()[0].get('linkerParam');
          if (currentGid) {
            const explodedValue = currentGid.split('&')[0].substring(4);
            document.cookie = `_ga_cda=${explodedValue};path=/;`;
          }
          const clientId = ga?.getAll?.()[0].get('clientId');
          if (gaCookie === null) {
            document.cookie = '_ga=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
          }

          if (gaCookie?.indexOf('GA') !== 0 && clientId) {
            document.cookie = `_ga=GA1.2.${clientId};path=/;`;
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, 2800);
  } catch (e) {
    console.error(e);
  }
};

export default getLinkers;
