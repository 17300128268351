import useTracking from '#hooks/useTracking';
import errors from '#utils/errors';

const Tracker = () => {
  errors.clientOnlyRequired('Tracker');

  useTracking();
  return '';
};

export default Tracker;
