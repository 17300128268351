import config from '#config';
import { useEffect } from 'react';

import firstClick from '#utils/gateway';
import getLinkers from '#utils/linkers';

export default function useTracking(): void {
  const { state } = window.history;
  const { pathname, hash, search } = window.location;
  const deps = [pathname, hash, search, state];

  useEffect(() => {
    if (!config.ANALYTICS) return;
    firstClick().catch(console.log);
    getLinkers().catch(console.log);
  }, deps);
}
